import React from 'react';

export default function About(props) {

	return(
		<div className="About-body">
			<br />
			<p>My first taste of photography started as a kid, when my parents bought me a “point and shoot” camera in the 90’s. My Dad was into photography himself so provided the support and encouragement to try this out for myself. It was very early in the digital camera age, so operated on traditional film. I still have strips of negatives from school trips and family events taken on that first camera. These images were all the typical ‘documenting’ style of images most people take with a point and shoot. Nothing artistic, and I was not attempting to be. I saw it as simply a way of recording the places I had been to and the things I had seen.</p><br />
			<p>During high school, I had further exposure to photography through spending a term in a photography class. The school had a dark room and we were taken through the whole process from composing images and camera settings, through to developing them ourselves in the school dark room. They were simple black and white photos, but seeing the whole process was a great insight into how the images are developed.</p><br />
			<p>Photography fell by the wayside for a while, and I didn’t pick it back up until 2014, when I booked a trip to spend three weeks hiking through the Himalayas. I knew that this would be an amazing setting and worthy of investing in something to properly record the trip. So I decided to buy an entry level DSLR.</p><br />
			<p>My trip through the Himalayas really kicked off my interest in photography. I came back from the trip with some nice photos, of spectacular mountains, rivers and the local communities. However, I felt that they could have conveyed the feeling and grandeur better, and in a more artistic way. But I didn’t know how to produce the impressive and artistic images you see in magazines and online. These images do a far better job of conveying the wonder and majesty of the landscapes than anything I could produce at the time. This started me on the path of learning what goes into producing these sorts of images. Surely, with some time and patients, I could produce images that make people stop and go wow!</p><br />
			<p>Since then I have spent many hours studying tutorials, other photographers I admire and travelling to locations around the world to develop my understanding and skills in photography. I have come to appreciate the amount of time it takes to plan and execute a photo shoot to get a great image. And to accept that the success rate from each shoot can be quite low, but to keep on trying and above all, appreciate the experience of being out in the locations.</p><br />
			<p>On this site you will see a selection of my favourite images I have taken. I hope you enjoy them.</p><br />
			<br />
			<br />
		</div>
	);
}