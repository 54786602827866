import React from 'react';
import './App.css';
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";
import ModalImage from "react-modal-image";

import image1 from "assets/img/gallery/IMG_0452-1_raya.jpg";
import image2 from "assets/img/gallery/IMG_0520-3_raya.jpg";
import image3 from "assets/img/gallery/IMG_0862-1_raya.jpg";
import image4 from "assets/img/gallery/IMG_0862-1_raya.jpg";
import image5 from "assets/img/gallery/IMG_0862-1_raya.jpg";
import image6 from "assets/img/gallery/IMG_0862-1_raya.jpg";
import image7 from "assets/img/gallery/IMG_1664-1_raya.jpg";
import image8 from "assets/img/gallery/IMG_2213-1_raya.jpg";
import image9 from "assets/img/gallery/IMG_2347-1_raya.jpg";
import image10 from "assets/img/gallery/IMG_2347-1_raya.jpg";
import image11 from "assets/img/gallery/IMG_2347-1_raya.jpg";
import image12 from "assets/img/gallery/IMG_2347-1_raya.jpg";
import image13 from "assets/img/gallery/IMG_3806-1_cleaned.jpg";
import image14 from "assets/img/gallery/IMG_3806-1_cleaned.jpg";
import image15 from "assets/img/gallery/IMG_4585-3_raya.jpg";
import image16 from "assets/img/gallery/IMG_4833-1_raya.jpg";
import image17 from "assets/img/gallery/IMG_4971-1.jpg";
import image18 from "assets/img/gallery/IMG_5171-1.jpg";
import image19 from "assets/img/gallery/IMG_5171-1.jpg";
import image20 from "assets/img/gallery/IMG_5171-1.jpg";
import image21 from "assets/img/gallery/IMG_6915-1_raya.jpg";
import image22 from "assets/img/gallery/IMG_2589.jpg";
import image23 from "assets/img/gallery/IMG_7659-1_edit2.jpg";
import image24 from "assets/img/gallery/IMG_7997-1_raya.jpg";
import image25 from "assets/img/gallery/IMG_7999-1.jpg";
import image26 from "assets/img/gallery/IMG_8008-1.jpg";
import image27 from "assets/img/gallery/IMG_8144-3_raya.jpg";
import image28 from "assets/img/gallery/IMG_8192-1_raya.jpg";
import image29 from "assets/img/gallery/IMG_8338-1_raya.jpg";
import image30 from "assets/img/gallery/IMG_8694-1_raya.jpg";
import image31 from "assets/img/gallery/IMG_9780-1_raya.jpg";

class GalleryMain extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			iCurPage: 1,
			bLandscape: true,
			bWildlife: true,
			bSports: true,
			bStreet: true
		};
	}
	
	render(){		
		
		var useStyles = makeStyles(styles);

		return (

			<div className="Gallery">
				<div className="Gallery-header">
					<br />
				</div>
				Landscapes
				<br/>
				<br/>
				<RenderLandscape enabled={this.state.bLandscape} />
				<br />
				<br />
				
				Wildlife
				<br/>
				<br/>
				<RenderWildlife enabled={this.state.bWildlife} />
				<br />
				<br />

				<br />
				<br />
				<br />
				
				
			</div>
			
		);
	}
}

function RenderLandscape(props){
	const enabled = props.enabled;
	if (enabled){
		
		return 	<div class="Gallery-row">

				  <div class="Gallery-column">
				  
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image1}
							large={image1}
							alt="Isle of Skye, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

						<ModalImage
							className="modal_img Gallery-column-image"
							small={image2}
							large={image2}
							alt="Adealide, Australia"
							hideDownload={true}
							hideZoom={true}
						/><br />

					
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image3}
							large={image3}
							alt="Isle of Skye, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />


				  </div>
				  
				  <div class="Gallery-column">
				  
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image7}
							large={image7}
							alt="Kyoto, Japan"
							hideDownload={true}
							hideZoom={true}
						/><br />

						<ModalImage
							className="modal_img Gallery-column-image"
							small={image8}
							large={image8}
							alt="Outer Hebrides, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

					
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image9}
							large={image9}
							alt="Outer Hebrides, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />


				  </div> 
				   
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image31}
							large={image31}
							alt="Isle of Skye, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

						<ModalImage
							className="modal_img Gallery-column-image"
							small={image15}
							large={image15}
							alt="Wilpena Pound, Australia"
							hideDownload={true}
							hideZoom={true}
						/><br />

					
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image16}
							large={image16}
							alt="San Sebastian, Spain"
							hideDownload={true}
							hideZoom={true}
						/><br />

			
				  </div>
				  
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image24}
							large={image24}
							alt="Isle of Skye, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

						<ModalImage
							className="modal_img Gallery-column-image"
							small={image27}
							large={image27}
							alt="Outer Hebrides, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

					
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image28}
							large={image28}
							alt="Fort Williams, Scotland"
							hideDownload={true}
							hideZoom={true}
						/><br />

				  </div>
	  
	</div>;
	}
	return <p></p>;
}

function RenderWildlife(props){
	const enabled = props.enabled;
	if (enabled){
		
		return 	<div class="Gallery-row"> 
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image13}
							large={image13}
							alt="Emu, Australia"
							hideDownload="true"
							hideZoom="true"
						/><br />
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image17}
							large={image17}
							alt="Macaque, Japan"
							hideDownload="true"
							hideZoom="true"
						/><br />
				  </div>
				   
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image18}
							large={image18}
							alt="Puffin, Wales"
							hideDownload="true"
							hideZoom="true"
						/><br />
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image21}
							large={image21}
							alt="Kangaroo, Australia"
							hideDownload="true"
							hideZoom="true"
						/><br />
				  </div>
				  
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image23}
							large={image23}
							alt="Highland Cow, Scotland"
							hideDownload="true"
							hideZoom="true"
						/><br />
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image25}
							large={image25}
							alt="Puffin, Wales"
							hideDownload="true"
							hideZoom="true"
						/><br />				
				  </div> 
				  
				  <div class="Gallery-column">
						<ModalImage
							className="modal_img Gallery-column-image"
							small={image26}
							large={image26}
							alt="Puffin, Wales"
							hideDownload="true"
							hideZoom="true"
						/><br />	

						<ModalImage
							className="modal_img Gallery-column-image"
							small={image22}
							large={image22}
							alt="Rainbow Lorikeet, Australia"
							hideDownload="true"
							hideZoom="true"
						/><br />						
				  </div>
				</div>;
	}
	return <p></p>;
}

function RenderSports(props){
	const enabled = props.enabled;
	if (enabled){
		
		return 	<div class="Gallery-row"> 
				  <div class="Gallery-column">
				  
				  </div>
				   
				  <div class="Gallery-column">
				  
				  </div>
				  
				  <div class="Gallery-column">
				  
				  </div> 
				  
				  <div class="Gallery-column">
				  
				  </div>
				</div>;
	}
	return  <p></p>;
}


export default GalleryMain