// base items
import React from 'react';
import './App.css';

//material ui base
import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/componentsSections/navbarsStyle.js";

// @material-ui/icons
import Search from "@material-ui/icons/Search";
import Email from "@material-ui/icons/Email";
import Face from "@material-ui/icons/Face";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Explore from "@material-ui/icons/Explore";
import HomeIcon from '@material-ui/icons/Home';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import InstagramIcon from '@material-ui/icons/Instagram';

import Home from "./Home.js";
import About from "./About.js";
import GalleryMain from "./GalleryMain.js";

import Button from 'components/CustomButtons/Button.js';

import Background from "assets/img/IMG_4585-3_raya.jpg";
import BackgroundBlank from "assets/img/Sunrise001_4K.jpg";

class App extends React.Component {
	constructor(props){
		super(props);
		this.state = {
			iCurPage: 1,
			curBackgroundImage: 'url('+Background+')',
		};
	}

	clickHome(){
			this.setState({iCurPage: 1}); 
			this.setState({curBackgroundImage: 'url('+Background+')'});
	}

	clickGallery(){
			this.setState({iCurPage: 2}); 
			this.setState({curBackgroundImage: 'url('+BackgroundBlank+')'});
	}

	clickAbout(){
			this.setState({iCurPage: 3}); 
			this.setState({curBackgroundImage: 'url('+BackgroundBlank+')'});
	}

	render(){		
		
		var useStyles = makeStyles(styles);
						

		return (
			<div className="App" style={{
			  backgroundImage: this.state.curBackgroundImage,
			  backgroundSize: "cover",
				height: "100vh",
				backgroundPosition: "center",
				backgroundAttachment: "fixed"}}>
				
					<div className="App-navbar">
						<div className="App-navbarLeftTitle">
							<p>Gareth Seglenieks Photography</p>
						</div>
						<div className="App-navbarRightLinks">
							<ul className="App-navbarLinksList">
								<li className="App-navbarLinksItem"><Button type="button" color="transparent" onClick={() => this.clickAbout()} ><div className="App-navbarLinksList-menuIcon"><EmojiPeopleIcon fontSize="small"/> </div>About</Button></li>
								<li className="App-navbarLinksItem"><Button type="button" color="transparent" onClick={() => this.clickGallery()} ><div className="App-navbarLinksList-menuIcon"><PhotoLibraryIcon fontSize="small"/> </div>Gallery</Button></li>
								<li className="App-navbarLinksItem"><Button type="button" color="transparent" onClick={() => this.clickHome()} ><div className="App-navbarLinksList-menuIcon"><HomeIcon fontSize="small"/> </div>Home</Button></li>
							</ul>
						</div>
					</div>
						
					<header className="App-header">
						<p>
							{(() => {
								switch (this.state.iCurPage) {
								  case 1:	return ;
								  case 2:	return <p>Gallery</p>;
								  case 3:	return <p>About</p>;
								  default:	return ;
								}
							})()}
						</p>
					</header>
				  
					<div className="App-body">
						<p>
							{(() => {
								switch (this.state.iCurPage) {
								  case 1:	return <Home />;
								  case 2:	return <GalleryMain />;
								  case 3:	return <About />;
								  default:	return <Home />;
								}
							})()}
						</p>
					</div>
				  
					<footer className="App-footer">
						<div className="App-footerSocialMedia">
							<div className="instagram-tooltip">
								<a href="https://instagram.com/gseglenieks" target="_blank"><InstagramIcon fontSize="small" /></a>
								<span className="instagram-tooltiptext">@gseglenieks</span>
							</div>
							
						</div>
					</footer>
					
			</div>
		);
	}
}

export default App;
