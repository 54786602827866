import React from 'react';
import './App.css';

export default function Home(props) {

	return(
		<div>
			
		</div>
	);
}